import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  query {
    ikio: file(relativePath: { eq: "003_ikio.jpg" }) {
      relativePath
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
const NotFoundPage = ({ data }) => (
  <Layout disabledFooter={true}>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>ページが見つからないみたいなんだ。ごめんね。</p>
    <Img fluid={data.ikio.childImageSharp.fluid} alt="" />
  </Layout>
)

export default NotFoundPage
